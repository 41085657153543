import React from 'react';
import Transition from '@components/Transition';
import Title from '@components/Title';
import Blurb from '@components/Blurb';
import { Helmet } from 'react-helmet';
import Text from '@components/Text';

const Slack = () => (
	<Transition>
		<Helmet>
            <title>Slack | Austen Payan</title>
            <link rel="canonical" href="https://austen.io/work/slack" />
        </Helmet>
		<Title>Slack</Title>
		<Blurb
            left={() => (
                <Text>
                    <ul>
                        <li>Senior Frontend Engineer, Search</li>
                        <li>2019 - Present</li>
                        <li>New York, New York</li>
                    </ul>
                </Text>
            )}
            right={() => (
                <>
                    <Text>I've been fortunate to work in various roles in the Search term at Slack, most recently being the DRI for all search-related frontend code. Since joining in 2019 I've helped ship multiple projects like browser pages, an IA redesign, an overhaul of our search experience, and continuous iterations on performance improvement.</Text>
                    <br />
                    <Text>More to come...</Text>
                </>
            )}
        />
	</Transition>
);

export default Slack;